import { render, staticRenderFns } from "./RNA-Array-QueryResult.vue?vue&type=template&id=17896b84&scoped=true&"
import script from "./RNA-Array-QueryResult.vue?vue&type=script&lang=js&"
export * from "./RNA-Array-QueryResult.vue?vue&type=script&lang=js&"
import style0 from "./RNA-Array-QueryResult.vue?vue&type=style&index=0&id=17896b84&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17896b84",
  null
  
)

export default component.exports