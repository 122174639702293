<template>
  <div class="wgcna-query-result">
    <SearchInput v-model="searchValue" :data-source="searchDataSource"
                 @search="handleTopSearch" style="width: 100%;"
                 placeholder="Example:Key words eg. liver Project ID eg.GSE015"/>

    <a-spin :spinning="spinning" tip="Loading...">
      <div class="wgcna-query-result-detail">
        <div class="wgcna-query-result-detail-item">
          <p>Gse_acc</p>
          <a :href="'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=' + this.wgcnaData.gse_acc"
             target="_blank">{{ this.wgcnaData.gse_acc }}</a>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Overall_design</p>
          <div>{{ this.wgcnaData.overall_design }}</div>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Study_title</p>
          <div>{{ this.wgcnaData.study_title }}</div>
        </div>

        <div class="wgcna-query-result-detail-item">
          <p>Summary</p>
          <div>{{ this.wgcnaData.summary }}</div>
        </div>
      </div>

      <div class="wgcna-query-result-pdf-list">
        <div v-if="filePath.heatmap.module">
          <span>Module-Tarit correlation heatmap</span>
          <img :src="filePath.heatmap.module" alt="step5-Module-trait-relationships"
               @click="showPdf(filePath.heatmap.module)" />
        </div>
        <div v-if="filePath.heatmap.network">
          <span>Genes Correlation heatmap</span>
          <img :src="filePath.heatmap.network" alt="step7-Network-heatmap" @click="showPdf(filePath.heatmap.network)" />
        </div>
      </div>

      <template v-if="categories.length > 0">
        <div class="wgcna-query-result-module-select">
          <div>
            <span>Choose module：</span>
            <a-select size="large" v-model="currentCategory" style="width: 200px" @change="changeCategory">
              <a-select-option v-for="i in categories" :key="i.name">
                {{ i.name }}
              </a-select-option>
            </a-select>
          </div>

          <a-tag color="orange" @click="downloadFile(filePath.modules[currentCategory])">
            <a-icon type="cloud-download"/>
            download
          </a-tag>
        </div>

        <a-table :scroll="{x:true}"
            :columns="columns"
            size="middle"
            bordered
            :rowKey="(record,index)=>index"
            :pagination="pagination"
            :data-source="tableData">
          <div
              slot="filterDropdown"
              slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
              style="padding: 0.5rem;"
          >
            <a-input
                v-ant-ref="c => (searchInput = c)"
                :placeholder="`Search ${column.title}`"
                :value="selectedKeys[0]"
                size="small"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >Search
            </a-button>
            <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
            >Reset
            </a-button>
          </div>
          <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template slot="customRender" slot-scope="text, record, index, column">
                <span v-if="searchText && searchedColumn === column.dataIndex">
                    <template
                        v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                        <mark
                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                            :key="i"
                            class="highlight"
                        >{{ fragment }}</mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
            <template v-else>{{ text }}</template>
          </template>
        </a-table>

        <div class="graph-group">
          <a-spin tip="Loading..." :spinning="chartLoading">
            <div class="graph-opera">
              <div>
                <span>布局方式：</span>
                <a-select default-value="force" style="width: 120px" @change="graphLayoutChange">
                  <a-select-option value="none">
                    固定布局
                  </a-select-option>
                  <a-select-option value="force">
                    力导向布局
                  </a-select-option>
                  <a-select-option value="circular">
                    环形布局
                  </a-select-option>
                </a-select>
              </div>

              <div style="margin-left: 1rem">
                <span>关系量：</span>
                <a-slider v-model="slider" @afterChange="handleSliderChange"/>
              </div>

              <div class="graph-select">
                <span>搜&emsp;&emsp;索：</span>
                <a-auto-complete
                    v-model="selectNode"
                    :data-source="dataSource"
                    placeholder="搜索模块名"
                    @search="searchGraph"
                    @select="selectGraph"
                >
                  <template slot="dataSource">
                    <a-select-option v-for="node in dataSource" :key="node.id" :value="node.name">
                      {{ node.name }}
                    </a-select-option>
                  </template>

                  <a-input>
                    <a-icon slot="suffix" type="search" class="certain-category-icon"/>
                  </a-input>
                </a-auto-complete>
              </div>
            </div>

            <Chart echarts-id="WGCNA-array-relation-graph" :height="chartHeight" :width="chartWidth"
                   ref="graphChart"
                   :option="WGCNARelationOption"/>
          </a-spin>

          <a-spin tip="Loading..." :spinning="chartLoading">
            <a-tabs default-active-key="0" v-if="enrichmentFolder">
              <a-tab-pane key="0" tab="GO-BP">
                <img :src="enrichmentFolder + 'GO_BP.enrichment.png'" class="bioproject-img" alt="GO-BP">
              </a-tab-pane>
              <a-tab-pane key="1" tab="GO-CC">
                <img :src="enrichmentFolder + 'GO_CC.enrichment.png'" class="bioproject-img" alt="GO-CC">
              </a-tab-pane>
              <a-tab-pane key="2" tab="GO-MF">
                <img :src="enrichmentFolder + 'GO_MF.enrichment.png'" class="bioproject-img" alt="GO-MF">
              </a-tab-pane>
              <a-tab-pane key="3" tab="KEGG">
                <img :src="enrichmentFolder + 'KEGG.enrichment.png'" class="bioproject-img" alt="KEGG">
              </a-tab-pane>
            </a-tabs>
          </a-spin>
        </div>
      </template>
    </a-spin>

    <a-modal v-model="pdfVisible" :footer="null" centered width="max-content">
      <img :src="pdfSrc" alt="" style="height:90vh;"/>
    </a-modal>
  </div>
</template>

<script>
import Chart from "@/components/Chart.vue";
import WGCNARelation from "@/chartsOptions/WGCNARelation";
import {downloadFile, parseCsv} from "@/utils/csv.js"
import pdf from 'vue-pdf';
import {get_rna_array_module} from "@/request/api.js"
import bus from '@/utils/bus'
import SearchInput from "@/components/SearchInput/index.vue";
import {fetchRNAArrayProjectList} from "@/request/niu_api";

// links:完整links,_links:当前selected的links
let links = [],_links = [];

export default {
  name: "queryResult",
  components: {
    SearchInput,
    pdf, Chart
  },
  data() {
    return {
      searchValue: "",
      searchDataSource:[],
      wgcnaData: {},
      categories: [],
      currentCategory: "",
      slider: 1,
      imgList: {},
      spinning: true,
      chartLoading: false,
      filePath: {
        modules: {},
        folder: "",
        heatmap: {
          module: "",
          network: ""
        }
      },
      columns: [],
      tableData: [],
      pagination: {
        pageSize: 10,
        showSizeChanger: true,
        showTotal(total) {
          return `Total ${total} items`
        },
      },
      /* 表格搜索 */
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      /* 表格搜索 End */
      WGCNARelationOption: null,
      chartHeight: "60vh",
      chartWidth: "100%",
      selectNode:"",
      dataSource: [],
      searchSelectNode: null,
      pdfVisible: false,
      pdfSrc: ""
    }
  },
  computed: {
    enrichmentFolder(){
      return this.filePath.folder + this.currentCategory + "/function_enrichment/";
    }
  },
  created() {
    this.wgcnaData = JSON.parse(window.localStorage.getItem('current_rna_array_wgcna_data'));
    const folder = this.common.source_url + `bged2-rnaarray-wgcna/${this.wgcnaData.gse_acc}/`;
    this.filePath.folder = folder;
    this.filePath.heatmap = {
      module: `${folder}step5-Module-trait-relationships.png`,
      network: `${folder}step7-Network-heatmap.png`
    }

    get_rna_array_module(this.wgcnaData.gse_acc).then(res => {
      this.categories = res.modules.map(i => {
        const name = i.split("/")[0];

        return {name: name,value: name}
      });
      this.currentCategory = this.categories[0].name;

      res.modules.forEach(module => {
        this.filePath.modules[module.split("/")[0]] = `${folder}${module}`;
      })

      this.txtLoad();

      this.spinning = false;
    })

    fetchRNAArrayProjectList({
      "search": "",
      "page": 1,
      "limit": 1000,
      "sort": "gse_acc",
      "order": "asc"
    }).then(res => {
      if (res.code === 0) {
        this.searchDataSource = res.data.data.map(item => ({
          ...item,
          key:item.gse_acc,
          value:item.gse_acc
        }));
      } else {
        this.$message.error(res.errMsg)
      }
    })
  },
  methods: {
    downloadFile,
    handleTopSearch() {
      this.$router.push({path: "/RNA-array-WGCNA", query: {search: this.searchValue}})
    },
    //表格搜索
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    //重置搜索
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = '';
    },
    txtLoad() {
      this.spinning = true;

      this.columns = [];

      parseCsv(this.filePath.modules[this.currentCategory], (csv) => {
        csv.sort((a, b) => b.weight - a.weight)

        Object.keys(csv[0]).forEach(item => {
          let obj = {
            title: item,
            dataIndex: item,
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender'
            },
            onFilter: (value, record) => record[item].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus();
                });
              }
            }
          }
          this.columns.push(obj)
        })

        this.tableData = csv;

        this.generateChart(csv);

        this.spinning = false;
      })
    },
    //查看pdf文件
    showPdf(pdf) {
      this.pdfSrc = pdf;
      this.pdfVisible = true;
    },
    changeCategory(c) {
      this.currentCategory = c;

      this.txtLoad();
    },
    generateChart(data) {
      this.chartLoading = true;

      this.slider = 1;
      this.selectNode = "";
      this.dataSource = [];

      WGCNARelation.series[0].data = [];
      WGCNARelation.series[0].links = [];
      WGCNARelation.series[0].categories = [];
      WGCNARelation.series[0].force={
        edgeLength: [15, 120],
        repulsion: [40,120],
        gravity: 0.20
      };
      this.WGCNARelationOption = WGCNARelation;

      let nodes = [];
      links = [];
      _links = [];

      data.forEach(item => {
        if(!nodes.includes(item.fromNode)){
          nodes.push(item.fromNode);
          WGCNARelation.series[0].data.push({
            name: item.fromNode,
            value: item.weight,
            altName: item.fromAltName,
            id: item.fromNode
          });
        }
        links.push({
          source: item.fromNode,
          sourceNode: item.fromNode,
          target: item.toNode,
          targetNode: item.toNode,
        });
      });

      const xNum = Math.ceil(Math.sqrt(nodes.length));
      const yNum = Math.ceil(nodes.length / xNum);
      const CanvasWidth = document.getElementById("WGCNA-array-relation-graph").offsetWidth;
      const CanvasHeight = document.getElementById("WGCNA-array-relation-graph").offsetHeight;
      //x,y轴上的间隔
      const xGap = Math.floor(CanvasWidth / xNum);
      const yGap = Math.floor(CanvasHeight / yNum)
      const getXY = (nodeData, index) => {
        index = index + 1;
        //Math.ceil(index / xNum)获取在第几行
        nodeData.x = (index % xNum) * xGap;
        nodeData.y = Math.ceil(index / xNum) * yGap;

        return nodeData;
      }
      WGCNARelation.series[0].data = WGCNARelation.series[0].data.map(getXY);

      WGCNARelation.series[0].links = links.slice(0,  Math.floor(links.length * this.slider / 100));
      WGCNARelation.toolbox.feature.myDownload = {
        show: true,
        title: "下载",
        icon: 'image:///images/echarts-toolbox-download.png',
        onclick: () => {
          this.echartsExportImg();
        }
      };

      this.WGCNARelationOption = WGCNARelation;
      this.chartLoading = false;
      bus.$emit("echartInitFinish");
    },
    handleSliderChange(value) {
      this.chartLoading = true;

      //WGCNARelation.series[0].links = links.slice(0,  Math.floor(links.length * value / 100));
      this.linksChange();
      this.WGCNARelationOption = WGCNARelation;

      this.chartLoading = false;
    },
    // 修改布局方式
    graphLayoutChange(value) {
      this.WGCNARelationOption.series[0].layout = value;
    },
    // 搜索模块
    searchGraph(searchText) {
      let dataSource = []
      this.WGCNARelationOption.series[0].data.forEach(item => {
        if (item.name.indexOf(searchText) !== -1 || item.altName.indexOf(searchText) !== -1) {
          dataSource.push(item)
        }
      })
      this.dataSource = dataSource;
    },
    // 选择模块
    selectGraph() {
      this.setSelectNodeData()

      this.linksChange();
    },
    setSelectNodeData() {
      let selectNodeIndex = this.WGCNARelationOption.series[0].data.findIndex(item => item.name === this.selectNode);
      //删除原先选中的节点的额外属性
      if (this.searchSelectNode !== null) {
        delete this.searchSelectNode.symbolSize;
        delete this.searchSelectNode.label;
      }

      this.WGCNARelationOption.series[0].data[selectNodeIndex].symbolSize = 40;
      this.WGCNARelationOption.series[0].data[selectNodeIndex].label = {
        show: true,
        position: "inside",
        formatter: '{b}'
      }

      //存储当前选择的节点
      this.searchSelectNode = this.WGCNARelationOption.series[0].data[selectNodeIndex];
    },
    linksChange() {
      this.chartLoading = true;

      let _links = [];
      links.forEach(edge => {
        if (edge.sourceNode !== this.selectNode && this.selectNode !== "") return;

        _links.push({
          source: edge.sourceNode,
          sourceNode: edge.sourceNode,
          target: edge.targetNode,
          targetNode: edge.targetNode,
        })
      })
      this.WGCNARelationOption.series[0].links = _links.slice(0,  Math.floor(_links.length * this.slider / 100));

      this.chartLoading = false;
    },
    echartsExportImg() {
      bus.$emit("globalLoading", {bool: true, tip: "正在下载中..."});

      const convertBase64UrlToBlob = (base64) => {
        const parts = base64.dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; i++) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
      };

      const myChart = this.$echarts.getInstanceByDom(
          document.getElementById("WGCNA-array-relation-graph")
      );
      this.chartHeight = "450vh";
      this.chartWidth = "450vw";
      myChart.setOption({
        series: [
          {
            force: {
              repulsion: 10,//斥力因子
            }
          }
        ]
      })
      myChart.resize();

      setTimeout(() => {
        //下载结束时执行
        const close = () => {
          this.chartHeight = "60vh";
          this.chartWidth = "100%";

          setTimeout(() => {
            myChart.setOption({
              series: [
                {
                  force: {
                    edgeLength: [15, 120],
                    repulsion: [40,120],
                    gravity: 0.20
                  }
                }
              ]
            })
            myChart.resize();
            bus.$emit("globalLoading", {bool: false, tip: "正在下载中..."})
          }, 1000)
        }

        myChart.resize();
        const url = myChart.getConnectedDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff", // 图表背景色
          excludeComponents: [// 保存图表时忽略的工具组件,默认忽略工具栏
            'toolbox'
          ],
          type: "png", // 图片类型支持png和jpeg
        });
        const a = document.createElement('a');
        a.download = `RNA-Array-WGCNA-relation-graph.png`;
        a.target = '_blank';
        a.href = url;
        // Chrome and Firefox
        if (typeof MouseEvent === 'function') {
          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          a.dispatchEvent(evt);
        } else {
          // IE
          const base64 = {
            dataURL: url,
            type: 'png',
            ext: 'png',
          };
          const blob = convertBase64UrlToBlob(base64);
          // 下载
          window.navigator.msSaveBlob(
              blob,
              `RNA-Array-WGCNA-relation-graph.png`
          );
        }

        close();
      }, 100)
    },
  },
  beforeDestroy() {
    bus.$off("echartInitFinish");
  }
}
</script>

<style scoped lang="scss">
.wgcna-query-result {
  background: #fff;
  width: 80rem;
  margin: 1.8rem 8rem;
  padding: 2rem;

  &-detail {
    margin-top: 1rem;

    &-item {
      margin-bottom: 2rem;

      p {
        margin-bottom: 0 !important;
        height: 37px;
        font-size: 21px;
        font-weight: 600;
        color: #333333;
        line-height: 37px;
      }

      div, a {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 37px;
      }
    }
  }

  &-pdf-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    div {
      width: 37rem;
      border-radius: 13px;
      border: 1px solid #E6E6E6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        height: 37px;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
        display: inline-block;
        margin: 1rem 0;
      }

      img {
        width: 25rem;
      }
    }
  }

  &-module-select {
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-tag {
      cursor: pointer;
      height: 33px;
      line-height: 33px;
      font-size: 16px;
    }

    & > div > span {
      height: 23px;
      font-size: 16px;
      font-weight: 600;
      color: #000000;
      line-height: 19px;
    }
  }
}

.graph-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  & > div {
    width: 36rem;
    height: calc(60vh + 0.5rem + 120px);
    padding: 1rem 2rem;
    border-radius: 13px;
    border: 1px solid #E6E6E6;
    overflow: hidden;
  }

  .graph-opera {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & > div {
      display: flex;
      align-items: center;

      & > span {
        width: 70px;
      }

      ::v-deep .ant-slider {
        width: 9rem;
      }
    }
  }

  .graph-select {
    width: 300px;
    margin: 0.5rem 0 20px 0;
  }
}

.bioproject-img {
  width: 100%;
}
</style>