//WGCNA网络互作图（关系图）
export default {
    tooltip: {
        formatter:function (p) {
            if (p.dataType === "node") {
                return `
                <div style="margin: 0 0 0;line-height:1;">
                    <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${p.seriesName}</div>
                    <div style="margin: 10px 0 0;line-height:1;">
                        <div style="margin: 0px 0 0;line-height:1;">
                            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span>
                            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${p.data.altName}</span>
<!--                            <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">-</span>-->
                            <div style="clear:both"></div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                    <div style="clear:both"></div>
                </div>
                `;
            }else if(p.dataType === "edge"){
                return `
                <div style="margin: 0 0 0;line-height:1;">
                    <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${p.seriesName}</div>
                    <div style="margin: 10px 0 0;line-height:1;">
                        <div style="margin: 0px 0 0;line-height:1;">
                            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#aaa;"></span>
                            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${p.data.sourceNode}</span>
                            <span style="font-size:14px;color:#666;font-weight:900">-></span>
                            <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${p.data.targetNode}</span>
                            <div style="clear:both"></div>
                        </div>
                        <div style="clear:both"></div>
                    </div>
                    <div style="clear:both"></div>
                </div>
                `;
            }
        }
    },
    grid:{
        containLabel:true,
        bottom:0
    },
    toolbox:{
        show:true,
        right:20,
        itemSize:20,
        feature: {
            restore: {
                icon:"image:///images/echarts-toolbox-refresh.png"
            },
            //自定义-富集分析
            // myEnrichment:{
            //     show:true,
            //     title:"富集分析",
            //     icon: 'image:///images/echarts-toolbox-enrichment.png',
            //     onclick: function (){
            //         alert('myToolHandler1')
            //     }
            // }
        }
    },
    animationDuration: 1500, //数据更新动画的时长。
    animationEasingUpdate: 'quinticInOut', //数据更新动画的缓动效果。
    series: [
        {
            name: "基因模块关系图",
            type: 'graph',
            layout: 'force', //force,none,circular
            animation: false,
            roma: true,
            label: {
                show: true, // 20240601: 该部分是否可改为默认展示全部基因名？
                position: 'top',
                formatter: '{b}'
            },
            data: [],
            categories: [],
            force: {
                edgeLength: [20, 100],
                repulsion: 100,//斥力因子。该值越大则节点之间的距离越远。
                gravity: 0.2 //节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
            },
            left:100,
            right:100,
            links: [],
            roam: true, //是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移，可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            nodeScaleRatio: 0.6, //鼠标漫游缩放时节点的相应缩放比例，当设为0时节点不随着鼠标的缩放而缩放
            draggable: false, //节点是否可拖拽，只在使用力引导布局(layout: 'force',)的时候有用。
            focusNodeAdjacency: true, //是否在鼠标移到节点上的时候突出显示节点以及节点的边和邻接节点。
            //edgeSymbol: ['circle', 'arrow'], //边两端的标记类型，可以是一个数组分别指定两端，也可以是单个统一指定。默认不显示标记，常见的可以设置为箭头，如下：edgeSymbol: ['circle', 'arrow']
            //edgeSymbolSize: 10, //边两端的标记大小，可以是一个数组分别指定两端，也可以是单个统一指定。
        }
    ]
}